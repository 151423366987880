import { useState, useEffect } from "react"
import { motion, AnimatePresence } from "framer-motion"
import { addPropertyControls, ControlType } from "framer"

// Define the code override component
export function AnimatedText({ words, font, color }) {
    const [index, setIndex] = useState(0) // Set initial index to 0 (or any other desired index)

    useEffect(() => {
        const interval = setInterval(() => {
            setIndex((prevIndex) => (prevIndex + 1) % words.length)
        }, 2500) // Change word every 5 seconds

        return () => clearInterval(interval)
    }, [words.length])

    const animationVariants = {
        exit: (i) => ({
            opacity: 0,
            y: -0,
            scale: 1.0, // Slight shrink as it exits
            transition: { duration: 0.2, delay: i * 0.05 },
        }),
        enter: (i) => ({
            opacity: [0, 1], // Fade in
            y: [0, 0], // Slide in from below
            scale: [1, 1], // Slight scale up for a dynamic effect
            transition: { duration: 0.3, delay: 0.2 + i * 0.05 }, // Smooth delay
        }),
    }

    const currentWord = words[index]

    return (
        <AnimatePresence mode="wait">
            {/* Animate the entire word with letter-by-letter transition */}
            <motion.span
                key={currentWord} // Ensure word-level transition
                style={{ position: "relative", display: "inline-block" }} // Use inline-block
                initial={index === 0 ? false : "enter"} // Prevent entrance animation for the first word
                animate="enter"
                exit="exit"
                variants={animationVariants}
            >
                {currentWord.split("").map((char, i) => (
                    <motion.span
                        key={`enter-${char}-${i}`}
                        style={{
                            position: "relative",
                            color,
                            ...font,
                            display: "inline-block", // Prevents extra spaces between letters
                        }}
                        custom={i}
                        variants={animationVariants}
                    >
                        {char === " " ? "\u00A0" : char}{" "}
                        {/* Handle space rendering */}
                    </motion.span>
                ))}
            </motion.span>
        </AnimatePresence>
    )
}

// Add property controls to make the font and other properties customizable in Framer UI
addPropertyControls(AnimatedText, {
    words: {
        type: ControlType.Array,
        title: "Words",
        propertyControl: {
            type: ControlType.String,
        },
        defaultValue: ["restaurant", "food chain", "retail store"],
    },
    font: {
        type: ControlType.Font,
        controls: "extended",
        title: "Font",
    },
    color: {
        type: ControlType.Color,
        title: "Text Color",
        defaultValue: "#000000",
    },
})
